import { useEffect } from 'react'
import { signIn } from 'next-auth/react'
import makeGetServerSideProps from '@utils/makeGetServerSideProps'
import { loginRedirectLink } from '@lib/links/login'
import { AUTH0_ORG_PREFIX } from '@utils/constants/auth'

function LoginForm() {
  useEffect(() => {
    const search = window.location.search

    const searchParams = new URLSearchParams(search)
    let callbackUrl
    let authorizationParams

    if (searchParams.has('callbackUrl')) {
      const callbackUrlParam = searchParams.get('callbackUrl')

      callbackUrl = callbackUrlParam.includes(window.location.protocol)
        ? callbackUrlParam
        : window.location.origin + callbackUrlParam

      const pathnameOrg = callbackUrlParam.split('/')?.[1]

      if (pathnameOrg?.includes(AUTH0_ORG_PREFIX)) {
        authorizationParams = {
          organization: pathnameOrg,
        }
      }
    }

    signIn('auth0', { callbackUrl }, authorizationParams)
  }, [])

  return null
}

export async function getServerSideProps(context) {
  return makeGetServerSideProps({
    links: [loginRedirectLink],
    addSharedLinks: false,
  })(context)
}

LoginForm.auth = {
  skipSession: true,
}

export default LoginForm
